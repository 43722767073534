<template>
  <div v-scrollanim id="experience">
    <h4 class="white--text my-2 mb-6 text-left">Experience</h4>
    <div class="timeline">
      <vue-timeline-update
        v-for="(experience, index) in experiences"
        :key="index"
        @click="experience.show = !experience.show"
        :date="experience.date"
        :title="experience.show ? experience.titleUp : experience.titleDown"
        :description="experience.show ? experience.description : null"
        icon="code"
        color="red"
        :thumbnail="experience.thumbnail"
        :is-last="experience.is_last"
        :category="experience.category"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      experiences: [
        {
          titleUp:
            "Senior Full-Stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Senior Full-Stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2024-09-02'),
          description: `
                      <a target='_blank' alt='AIrecruit' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: AIrecruit</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Gebere' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: AIrecruit</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>September 2024 - Present</b>
                        <li>AIrecruit is a platform that helps companies find the right candidates for their open positions. It uses AI to match candidates to jobs based on their skills and experience.</li>
                        <li>It also provides tools for companies to manage their recruitment process. Additionally you can:</li>
                        <ul>
                          <li> Manage job postings and imports, ensuring accurate and timely updates.</li>
                          <li> Track and monitor applicant progress throughout the hiring process.</li>
                          <li> Coordinate AI-powered interview scheduling, including conducting video/audio interviews.</li>
                          <li> Provide support for interview preparation, offering guidance to candidates.</li>
                          <li> Evaluate and score interviews to assess candidate suitability.</li>
                          <li> Integrate and synchronize applicant details with Applicant Tracking Systems (ATS).</li>
                        </ul>
                      </ul>
                      <li>#React #Next.js #TailwindCSS #Vercel #Python #FastAPI #PostgreSQL #OpenAI #LLMs #RealtimeAPI #AWS</li>
                      `,
          show: true,
          is_last: false,
          category: 'Latest',
          thumbnail: 'airecruit.png',
        },
        {
          titleUp:
            "Full-Stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Full-Stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2022-09-12'),
          description: `
                      <a target='_blank' alt='Cropconex' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: CropConex</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Cropconex' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Cropconex</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>September 2022 - July 2024</b>
                        <li>
                        I worked remotely for CropConex, a US-based company that streamlines coﬀee commodity contracts, global
                        supply chain management, and international payments. CropConex simplifies logistics and trade with features
                        like: </li>
                        <ul>
                          <li>Registering coﬀee harvests, including details like source, weight, price, variety, and certifications.</li>
                          <li>Monitoring green coﬀee stock levels for better production planning and visibility across warehouses.</li>
                          <li>Managing coﬀee sales through contracts or listing products on the Marketplace to find new buyers.</li>
                          <li>Comparing logistics costs for freight or directly sharing order details with providers.</li>
                          <li>Handling online payments and invoices.</li>
                        </ul>
                        <li>The platform supports eﬃcient management across inventory, contracts, orders, payments, and more.</li>
                      </ul>
                      <li>#Vue.js #Node.js #Nuxt.js #GraphQL #Jira #ApolloClient #JWT #PostgreSQL #Express.js #Retool</li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'cropconex.png',
        },
        {
          titleUp:
            "Front End Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Front End Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2022-07-18'),
          description: `
                      <a target='_blank' alt='Chatfully LLC' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Chatfully LLC</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Gebere' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Chatfully</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>July 2022 - December 2022</b>
                        <li>Chatfully unifies customer messages and automate follow-up with an omnichannel chat tool that an entire team can use.</li>
                        <li>With Chatfully, you can centralize communication in team inboxes, chat at scale, and follow-up with opportunities faster.</li>
                      </ul>
                      <li>#Quasar #Vue.js #Javascript #Clickup </li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'chatfully.png',
        },
        {
          titleUp:
            "Senior Software Engineer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Senior Software Engineer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2021-05-10'),
          description: `
                      <a target='_blank' alt='OrbitHealth Inc' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Orbit Inc.</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='OrbitHealth Inc' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Orbit 2.0</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>May 2021 - September 2022</b>
                        <li>I worked on many projects in orbit health inc, and one of them is Orbit 2.0</li>
                        <li>It is a comprehensive healthcare management system that automates key functions of healthcare facilities. The system includes six modules:
                          <ul>
                            <li>Authentication and setting - user signup, sign-in, roles, permissions, settings, etc</li>
                            <li>Patient - appointment, triage, MCH, admissions, etc</li>
                            <li>Laboratory and Imaging - tests, orders, results, reports, etc</li>
                            <li>Pharmacy - prescriptions, dispenses, inventory, reports. etc</li>
                            <li>Finance - invoices, finance groups, deposits, reports, etc</li>
                          </ul>
                        </li>
                        <li>Orbit 2.0 is a complete overhaul of Orbit 1.0, improving design, implementation, and technology. 
                        It has helped many facilities eliminate paper-based processes, improving eﬃciency and service quality.</li>
                      </ul>
                      <li>#Vue.js #Node.js #Nuxt.js #Vuetify #GraphQL #Jira #ApolloClient #JWT #PostgreSQL #Express.js </li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'orbit-health.png',
        },
        {
          titleUp:
            "Front End Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Front End Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2022-03-01'),
          description: `
                      <a target='_blank' alt='Gebere' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Gebere Inc.</b> 
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Gebere' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Gebere</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>October 2021 - March 2022</b>
                        <li>A crowd-farming web app designed to provide public funding for farming projects. 
                          It features two front-end deployments: one for public investors and another for admins to manage system operations. 
                          Admins define project details and funding needs, which are then accessible to investors through the public app.</li>
                      </ul>
                      <li> #Vue.js #Typescript #TailwindCSS </li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'gebere.png',
        },
        {
          titleUp:
            "Senior Backend Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Senior Backend Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2021-05-22'),
          description: `
                      <a target='_blank' alt='Ekub' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Ekub Inc.</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Ekub' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Ekub</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>May 2021 - November 2022</b>
                        <li>Ekub is an Ethiopian ROSCA(rotating savings and credit association) system. </li>
                        <li>It has specific rules and guidelines. People will gather in groups to save money weekly, monthly, or annually.</li> 
                        <li>There's a lot drawn on specific dates and it will be allotted to one of the group members.</li>
                        <li>You can also borrow from the Ekub in case of emergency.</li>
                        <li>We have automated the process and eliminated paperworks.</li>
                      </ul>
                      <li>#Node.js #GraphQL #Hasura #Express.js</li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'ekub.png',
        },
        {
          titleUp:
            "Backend Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Backend Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2021-05-22'),
          description: `
                      <a target='_blank' alt='Tiyake' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Tiyake</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Tiyake' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Tiyake</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>August 2021 - January 2022</b>
                        <li>Tiyake is a stackoverflow like application where anyone can post questions and people respond to his question</li>
                        <li>Users can answer, comment, upvote, and downvote questions/answers</li> 
                        <li>There are special moderators that controls the moral guidelines of the app is maintained.</li>
                      </ul>
                      <li>#Node.js #GraphQL #Hasura #Express.js</li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'tiyake.png',
        },
        {
          titleUp:
            "Full-stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Full-stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2019-09-11'),
          description: `
                      <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Minab Tech</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: JCC NRP</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>December 2020 - May 2021</b>
                        <li>A platform for local and overseas job seekers to register, take automated assessments, and access job matching
                          services. It enables users to build profiles, undergo free screening, and track training, testing, and certifications
                          while digitalizing documentation for legal, safe overseas work. The platform also maps the national jobseeker
                          landscape, oﬀering data-driven job matching for both local and foreign employment needs.</li>
                      </ul>
                      <li>#Vue.js #Node.js #Nuxt.js #PostgreSQL #GraphQL #Hasura #Express.js</li>
                      <br />
                      <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Website for Habesha Entrepreneurs credit and saving Association</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>December 2020 - March 2021</b>
                        <li>Habesha Entrepreneurs Credit and Saving Association (SACCO) aims to narrow the financial gap for SMEs, create
                          market linkages, and shorten market chains. The platform helped the company register more members and
                          expand its reach</li>
                        <li>
                          <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none' href='https://habeshaentreprenurssacco.com/'>
                            Link: https://habeshaentreprenurssacco.com/
                          </a>
                        </li>
                      </ul>
                      <li>#Vue.js #Nuxt.js #GraphQL #Hasura</li>
                      <br />
                      <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Hahujobs University</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>September 2020 - February 2021</b>
                        <li>A web application designed to support remote learning for university and TVET students during the pandemic.
                          Universities can register, get verified, and post course materials (videos, PDFs, images) and announcements to
                          students or the public. Students can download content and engage with posts through comments.</li>
                      </ul>
                      <li>#GraphQL #Express.js #Nuxt.js #PostgreSQL #Node.js #Vue.js #vuetify</li>
                      <br />
                      <a target='_blank' alt='Minab Tech' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: HRMS (Human resource management system)</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>September 2019 - September 2020</b>
                        <li>A comprehensive Human Resource Management System with modules for employee registration/termination,
                          attendance, payroll, leave, and training management. It helps clients eliminate paperwork, reduce errors, and
                          increase productivity by automating tasks like salary calculations based on attendance and leave status.</li>
                      </ul>
                      <li>#Vue.js #Node.js #Loopback.js #MongoDB</li>
                      `,
          show: false,
          is_last: false,
          thumbnail: 'minab.png',
        },
        {
          titleUp:
            "Full-stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-up'></i>",
          titleDown:
            "Full-stack Developer &nbsp;&nbsp; <i class='fa-solid fa-chevron-down'></i>",
          date: new Date('2018-06-20'),
          description: `
                      <a target='_blank' alt='Epsilon Technologies' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #a7121d'>Company: Epsilon Technologies</b>
                      </a>
                      <br />
                      <br />
                      <a target='_blank' alt='Epsilon Technologies' rel='noopener noreferrer' style='text-decoration: none'>
                        <b style='color: #e2e2e2'>#Project: Taza Jobs</b> 
                      </a>
                      <ul style='color: grey'>
                        <b>November 2018 - February 2020</b>
                        <li>I have worked on several projects at Epsilon Technologies, with <strong> TazaJobs </strong> being the main one.</li>
                        <li>TazaJobs is a job search and recruitment platform with a mobile app for job seekers and a web app for employers.</li>
                        <li>Job seekers receive personalized job listings based on their preferences, view company details, and save jobs for
                        future reference.</li>
                        <li>Job seekers can easily apply to jobs and create professional CVs.</li>
                        <li>Employers can post job openings and connect with job seekers eﬃciently.</li>
                      </ul>
                      <li>#Vue.js #MongoDB #Node.js #Loopback.js #JWT</li>
                      `,
          show: false,
          is_last: true,
          thumbnail: 'epsilon.jpg',
        },
      ],
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.before-enter {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 0.9s ease-out;
}

.enter {
  opacity: 1;
  transform: translateX(0px);
}
</style>
