<template>
  <v-app-bar color="#111111" dark flat app class="navbar">
    <img
      @click="scroll('home')"
      src="muluken-logo.png"
      :height="
        $vuetify.breakpoint.xs
          ? '26px'
          : $vuetify.breakpoint.sm
          ? '30px'
          : '36px'
      "
      style="cursor: pointer"
      class="ml-4"
    />
    <v-spacer></v-spacer>
    <div class="hidden-sm-and-down mr-4">
      <v-btn text @click="scroll('home')">Home</v-btn>
      <v-btn text @click="scroll('about')">About</v-btn>
      <v-btn text @click="scroll('education')">Education</v-btn>
      <v-btn text @click="scroll('experience')">Experience</v-btn>
      <v-btn text @click="scroll('tools')">Tools</v-btn>
    </div>
    <v-icon
      aria-label="menu icon"
      class="hidden-md-and-up"
      @click="drawer = !drawer"
      >fas fa-bars</v-icon
    >
    <v-navigation-drawer
      v-model="drawer"
      class="mobile-drawer hidden-lg-and-up"
      hide-overlay
      app
      temporary
      right
      color="#111111"
    >
      <v-list nav flat tile dense color="#111111">
        <v-list-item-group>
          <v-list-item class="px-4 mb-2">
            <v-btn text block @click="scroll('home')">Home</v-btn>
          </v-list-item>
          <v-list-item class="px-4 mb-2">
            <v-btn text block @click="scroll('about')">About</v-btn>
          </v-list-item>
          <v-list-item class="px-4 mb-2">
            <v-btn text block @click="scroll('education')">Education</v-btn>
          </v-list-item>
          <v-list-item class="px-4 mb-2">
            <v-btn text block @click="scroll('experience')">Experience</v-btn>
          </v-list-item>
          <v-list-item class="px-4 mb-2">
            <v-btn text block @click="scroll('tools')">Tools</v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    scroll(id) {
      const element = document.getElementById(id);
      let pos = element.style.position;
      let top = element.style.top;
      element.style.position = "relative";
      element.style.top = "-70px";
      element.scrollIntoView({ behavior: "smooth" });
      element.style.top = top;
      element.style.position = pos;
    },
  },
};
</script>

<style scoped>
.navbar {
  width: 100% !important;
  max-width: 100vw !important;
}

.v-app-bar {
  width: 100% !important;
  max-width: 100vw !important;
}

.v-navigation-drawer {
  height: 100vh !important;
  top: 0 !important;
  padding-top: 64px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera when the mobile drawer is open */
.navbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox when the mobile drawer is open */
.navbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
